import {Button, Table, Spin, Modal} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import styles from '../styles/VehiclesUI.module.css';

const VehiclesUI = props => {
  const {
    loading,
    vehicles,
    vehicle,
    branches,
    userList,
    openEditor,
    editorVisible,
    closeEditor,
    newVehicle,
    confirmDelete,
    handleVehicle,
  } = props;

  const getStatus = status => {
    if (status === 1) {
      return 'Activo';
    }
    return 'Inactivo';
  };

  const getLocationName = location => {
    let name = '-';
    const index = branches.findIndex(el => el.id === location);
    if (index > -1) {
      name = branches[index].zone;
    }
    return name;
  };


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Usuario',
      dataIndex: 'name',
      align: 'center',
      ...getColumnSearchProps('user'),
    },
    {
      title: 'Placa',
      dataIndex: 'plate',
      align: 'center',
      ...getColumnSearchProps('plate'),
    },
    {
      title: 'Estado',
      dataIndex: 'available',
      align: 'center',
      render: val => getStatus(val),
    },
    {
      title: 'Tienda',
      dataIndex: 'branch_id',
      align: 'center',
      render: val => getLocationName(val),
    },
    {
      title: 'Acciones',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          {/* 
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          */}
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/ajustes"
        title="Listado de drivers"
        actions={
          <Button
            onClick={newVehicle}
            type="primary"
            size="large"
            className="main-button">
            AGREGAR DRIVER
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={vehicles}
            rowKey="id"
          />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          vehicle={vehicle}
          branches={branches}
          userList={userList}
          handleVehicle={handleVehicle}
        />
      </Modal>
    </div>
  );
};

export default VehiclesUI;
