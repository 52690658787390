import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../redux/slices/user";
import { message, Modal } from "antd";
import moment from "moment";
import {
  getAllBranches,
  selectBranches,
  takeOutBranch,
  DeliveryBranch,
  deleteLocation,
} from "../../redux/slices/branches";
import BranchesUI from "./components/BranchesUI";

const Branches = () => {
  const [loading, handleLoading] = useState(true);
  const [branch, handleBranch] = useState(null);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [times, setTimes] = useState([moment(), moment()]);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const initialFetch = useCallback(async () => {
    const response = await dispatch(getAllBranches(user.id));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const openEditor = (branchToEdit) => {
    handleBranch(branchToEdit);
    handleEditorVisible(true);
  };

  const newBranch = () => {
    handleBranch(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleBranch(null);
    handleEditorVisible(false);
  };

  const handleTakeOut = async (idBranch, val) => {
    const response = await dispatch(takeOutBranch(idBranch, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const handleDelivery = async (idBranch, val) => {
    const response = await dispatch(DeliveryBranch(idBranch, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const confirmDelete = (idBranch) => {
    Modal.confirm({
      title: "¿Estás segúro de eliminar esta sucursal?",
      content: "Esta información no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeBranch(idBranch);
      },
      onCancel() {},
    });
  };

  const removeBranch = async (idBranch) => {
    const response = await dispatch(deleteLocation(idBranch));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Sucursal eliminada correctamente");
    }
  };

  return (
    <BranchesUI
      loading={loading}
      branches={branches}
      branch={branch}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      initialFetch={initialFetch}
      newBranch={newBranch}
      handleTakeOut={handleTakeOut}
      handleDelivery={handleDelivery}
      handleBranch={handleBranch}
      scheduleVisible={scheduleVisible}
      setScheduleVisible={setScheduleVisible}
      times={times}
      setTimes={setTimes}
      confirmDelete={confirmDelete}
    />
  );
};

export default Branches;
