import { saveAs } from "file-saver";
import XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const exportData = (orders) => {
  const wb = XLSX.utils.book_new();
  const title = "Reporte de Ordenes";

  wb.Props = {
    Title: title,
    Subject: "Reporte de Ordenes",
    Author: "Buffalo Wings",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Ordenes");
  const ws_data = [
    ["Ticket", "Usuario", "Fecha", "Sucursal", "Total", "Metodo", "Estado"],
  ];

  orders.forEach((order) => {
    const newRegister = [
      order.ticket,
      `${order.id_user.name} ${order.id_user.lastname}`,
      moment
        .tz(order.created_time, "America/El_Salvador")
        .format("DD/MM/YYYY, HH:mm a"),
      order.branch.zone || "-",
      order.total,
      order.payment_method === 1 ? "Efectivo" : "Tarjeta",
      order.status === 12 ? "Finalizada" : "Cancelada",
    ];
    ws_data.push(newRegister);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Ordenes"] = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${title}.xlsx`
  );
};

export default exportData;
