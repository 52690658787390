import { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  Button,
  TimePicker,
  message,
} from "antd";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import cx from "classnames";
import moment from "moment";
import {
  editLocation,
  newLocation,
  newImage,
} from "../../../redux/slices/branches";
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import { ReactComponent as Gps } from "../../../assets/icons/gps.svg";
import Close from "../../../components/Close";
import styles from "../styles/Editor.module.css";

const { TextArea } = Input;

const libraries = ["places"];
const format = "HH:mm";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 13.701319108430834,
  lng: -89.22441469943618,
};

const Editor = ({ close, branch, handleBranch }) => {
  const searchbox = useRef(null);
  const [sending, handleSending] = useState(false);
  const [mapLoaded, handleMapLoaded] = useState(false);
  const [preview, handlePreview] = useState(null);
  const [form] = Form.useForm();
  const map = useRef(null);
  const dispatch = useDispatch();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBJRhyvTnDog1bbKhC-o0iPgdY3V3ikTFo",
    libraries,
  });

  useEffect(() => {
    const initialFetch = async () => {
      try {
        if (branch && mapLoaded) {
          const newValues = {
            zone: branch.zone,
            address: branch.address,
            phone: branch.phone,
            venue: branch.venue,
          };
          if (branch.schedules) {
            newValues.monday = getHours("monday");
            newValues.tuesday = getHours("tuesday");
            newValues.wednesday = getHours("wednesday");
            newValues.thursday = getHours("thursday");
            newValues.friday = getHours("friday");
            newValues.saturday = getHours("saturday");
            newValues.sunday = getHours("sunday");
          }
          if (branch.image) {
            handlePreview(branch.image);
          }
          form.setFieldsValue(newValues);
          map.current.setCenter({
            lat: parseFloat(branch.latitude),
            lng: parseFloat(branch.longitude),
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    initialFetch();
  }, [branch, mapLoaded]);

  const getHours = (day) => {
    const newHours = JSON.parse(branch.schedules);
    const index = newHours.findIndex((el) => el.day === day);
    if (index > -1) {
      const schedule = newHours[index];
      return [
        moment(schedule.open, "hh:mmA"),
        moment(schedule.close, "hh:mmA"),
      ];
    } else {
      return [moment(), moment()];
    }
  };

  const onPlacesChanged = () => {
    const location = searchbox.current.getPlaces()[0].geometry.location;
    map.current.setCenter({
      lat: location.lat(),
      lng: location.lng(),
    });
  };

  const renderMap = () => {
    const onLoadSearchbox = (ref) => {
      searchbox.current = ref;
      handleMapLoaded(true);
    };

    const onLoadMap = (ref) => {
      map.current = ref;
    };

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoadMap}
        zoom={16}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: false,
        }}
      >
        <StandaloneSearchBox
          onLoad={onLoadSearchbox}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Buscar..."
            className={styles.searchbox}
          />
        </StandaloneSearchBox>
      </GoogleMap>
    );
  };

  const getHourFormat = (label, values) => {
    return {
      day: label,
      open: values[0].format("hh:mmA"),
      close: values[1].format("hh:mmA"),
    };
  };

  const uploadImage = async (valueImage, id) => {
    if (preview !== branch?.image || !branch) {
      const infoImage = new FormData();
      infoImage.append("id", id);
      infoImage.append(
        "image",
        valueImage?.length ? valueImage[0]?.originFileObj : preview
      );
      const response = await dispatch(newImage(infoImage));
      if (response.status === "success") {
        message.success("Cambios realizados con éxito");
        return response;
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
        return false;
      }
    } else {
      message.success("Cambios realizados con éxito");
      return false;
    }
  };

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (preview && preview.length) {
        const newCenter = map.current.getCenter().toJSON();
        const newHours = [
          getHourFormat("monday", values.monday),
          getHourFormat("tuesday", values.tuesday),
          getHourFormat("wednesday", values.wednesday),
          getHourFormat("thursday", values.thursday),
          getHourFormat("friday", values.friday),
          getHourFormat("saturday", values.saturday),
          getHourFormat("sunday", values.sunday),
        ];
        const info = {
          zone: values.zone,
          address: values.address,
          phone: values.phone,
          latitude: newCenter.lat,
          longitude: newCenter.lng,
          schedules: newHours,
          status: 1,
          venue: values.venue,
        };
        if (branch) {
          info.id = branch.id;
        }
        if (branch) {
          const response = await dispatch(editLocation(info));
          if (response.status === "success") {
            await uploadImage(values.image, branch.id);
          } else {
            message.error("¡Hubo un problema! Inténtalo de nuevo");
          }
        } else {
          const response = await dispatch(newLocation(info));
          if (response.status === "success") {
            const response_image = await uploadImage(
              values.image,
              response.branch.id
            );
            handleBranch(response_image.branch);
          } else {
            message.error("¡Hubo un problema! Inténtalo de nuevo");
          }
        }
      } else {
        message.error("Seleciona una imagen válida");
      }

      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {branch ? "Editar sucursal" : "Nueva sucursal"}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row gutter={[40, 40]}>
          <Col span={15}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>NOMBRE</span>}
                  name="zone"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar nombre" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>TELÉFONO</span>}
                  name="phone"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar teléfono" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>VENUE</span>}
                  name="venue"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar venue" }]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span className={styles.label}>DIRECCIÓN</span>}
                  name="address"
                  className={styles.itemColumn}
                  rules={[{ required: true, message: "Ingresar dirección" }]}
                >
                  <TextArea rows={3} className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className={styles.mapWrapper}>
                  {isLoaded && renderMap()}
                  {isLoaded && <Gps className={styles.gps} />}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={<span className={styles.label}>IMAGEN BASE</span>}
              className={styles.itemColumn}
              extra={
                <span className={styles.center}>
                  (Formatos jpg o png de 1000px ancho)
                </span>
              }
            >
              <Upload
                maxCount={1}
                showUploadList={false}
                customRequest={(e) => dummyRequest(e, handlePreview)}
                beforeUpload={beforeUpload}
                listType="picture"
              >
                <Button size="large" className={styles.uploadImage}>
                  Cambiar imagen
                </Button>
              </Upload>
            </Form.Item>
            <div
              className={styles.preview}
              style={{ backgroundImage: `url(${preview})` }}
            />
            <div className={styles.hours}>
              <span className={cx(styles.label, styles.labelSection)}>
                HORARIOS
              </span>
              <div className={styles.hoursList}>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      LUNES
                    </span>
                  }
                  name="monday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      MARTES
                    </span>
                  }
                  name="tuesday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      MÍERCOLES
                    </span>
                  }
                  name="wednesday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      JUEVES
                    </span>
                  }
                  name="thursday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      VIERNES
                    </span>
                  }
                  name="friday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      SÁBADO
                    </span>
                  }
                  name="saturday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className={cx(styles.label, styles.labelHour)}>
                      DOMINGO
                    </span>
                  }
                  name="sunday"
                  rules={[{ required: true, message: "Ingresar horario" }]}
                >
                  <TimePicker.RangePicker
                    format={format}
                    className={styles.input}
                    placeholder={["Apertura", "Cierre"]}
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
              >
                GUARDAR SUCURSAL
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
