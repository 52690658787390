import { createSlice } from "@reduxjs/toolkit";
import {
  getClients,
  getClient,
  changeActive,
  getClientOrders,
} from "../../api/Clients";

export const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    clients: [],
    client: {},
    page: 1,
    loaded: false,
  },
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setActive: (state, action) => {
      state.client.Status = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClients, setClient, setActive, setPage, setLoaded } =
  clientsSlice.actions;

export const getAllClients = (page) => async (dispatch) => {
  try {
    const response = await getClients(page);
    if (!response.error && response.status === 200) {
      dispatch(setClients(response.data.users));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const activeClient = (id, val) => async (dispatch) => {
  try {
    dispatch(setActive(!val));
    const response = await changeActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setActive(val));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setActive(val));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getClientDetails = (client) => async (dispatch) => {
  try {
    const response = await getClient(client);
    if (!response.error && response.status === 200) {
      const response_orders = await getClientOrders(client);
      if (!response_orders.error && response_orders.status === 200) {
        const { user } = response.data;
        user.orders = response_orders.data.orders;
        dispatch(setClient(user));
        return {
          status: "success",
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectClients = (state) => state.clients.clients;
export const selectClient = (state) => state.clients.client;
export const selectPage = (state) => state.clients.page;
export const selectLoaded = (state) => state.clients.loaded;

export default clientsSlice.reducer;
