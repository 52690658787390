import {useState, useEffect} from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Select,
    TreeSelect,
    message,
  } from 'antd';
  import {useDispatch} from 'react-redux';
  import {createQuestion, updateQuestion} from '../../../redux/slices/ratings';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const Editor = ({close, question, handleQuestion}) => {
    const [sending, handleSending] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
  
    useEffect(() => {
      const initialFetch = async () => {
        if (question) {
          const newValues = {
            name: question.name,
            active: question.active,
            dateBegin: question.dateBegin,
            dateEnd: question.dateEnd
          };
          form.setFieldsValue(newValues);
        }
      };
      initialFetch();
    }, [question]);
  
  
    const save = async () => {
      try {
        handleSending(true);
        const values = await form.validateFields();
       
        const info = {
          name: values.name,
          active: values.active,
          dateBegin: values.dateBegin,
          dateEnd: values.dateEnd,
        };
        if (question) {
          info.id = question.id;
        }
        if (question) {
          const response = await dispatch(updateQuestion(info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        } else {
          const response = await dispatch(createQuestion(info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
            handleQuestion(response.question);
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        }
        handleSending(false);
      } catch (e) {
      }
    };
  
    return (
      <div className={styles.editor}>
        <div className={styles.header}>
          <span className={styles.title}>
            {question ? 'Editar Indicador' : 'Nueva Indicador'}
          </span>
          <Close action={close} />
        </div>
        <Form
          name="question-editor"
          autoComplete="off"
          form={form}
          colon={false}
          requiredMark={false}
          onFinish={save}
        >
          <Row gutter={20}>
          <Col span={20}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    Titulo del Indicador
                  </span>
                }
                name="name"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar pregunta'}]}
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
            </Col>
            </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    FECHA INICIO
                  </span>
                }
                name="level"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar tipo'}]}
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className={styles.label}>
                    FECHA FINALIZACION
                  </span>
                }
                name="level"
                className={styles.itemColumn}
                rules={[{required: true, message: 'Ingresar tipo'}]}
              >
                <Input size="large" className={styles.input} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item className={styles.itemSubmit}>
                <Button
                  loading={sending}
                  className={styles.submit}
                  size="large"
                  type="primary"
                  htmlType="submit">
                  GUARDAR INDICADOR
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
  
  export default Editor;