import instance from "./Request";

export const getSlides = async () => {
  const request = await instance();
  let data = await request.get("/banners").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeActive = async (banner, status) => {
  const request = await instance();
  let data = await request
    .post("/banners/status", { id: banner, status })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newBanner = async (banner) => {
  const request = await instance();
  let data = await request.post("/banners/create", banner).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const editBanner = async (banner) => {
  const request = await instance();
  let data = await request.post("/banners/update", banner).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deleteBanner = async (id) => {
  const request = await instance();
  let data = await request.post("/banners/delete", { id }).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const uploadImage = async (image) => {
  const request = await instance(true);
  let data = await request.post("/banners/upload", image).catch((error) => {
    return {
      error,
    };
  });
  return data;
};
