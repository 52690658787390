import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import {
  getPlatformRatings,
  selectRatings,
  selectAverage,
} from "../../redux/slices/ratings";
import RatingsUI from "./components/RatingsUI";

const Ratings = () => {
  const [loading, handleLoading] = useState(true);
  const ratings = useSelector(selectRatings);
  const average = useSelector(selectAverage);
  const [beginDate, setBeginDate] = useState(
    moment.tz("America/El_Salvador").subtract(7, "days")
  );
  const [endDate, setEndDate] = useState(moment.tz("America/El_Salvador"));
  const [platform, setPlatform] = useState(1);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const config = {
      start_date: beginDate.format("YYYY-MM-DD 00:00:00"),
      end_date: endDate.format("YYYY-MM-DD 23:59:59"),
      id: platform,
    };
    const response = await dispatch(getPlatformRatings(config));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [beginDate, dispatch, endDate, platform]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleDates = (newDates) => {
    dispatch(setBeginDate(newDates[0]));
    dispatch(setEndDate(newDates[1]));
  };

  return (
    <RatingsUI
      loading={loading}
      ratings={ratings}
      average={average}
      platform={platform}
      setPlatform={setPlatform}
      beginDate={beginDate}
      endDate={endDate}
      handleDates={handleDates}
    />
  );
};

export default Ratings;
