import { Table, Spin, Modal, Row, Col, Button, Switch } from "antd";
import cx from "classnames";
import moment from "moment";
import Header from "../../../components/HeaderList";
import { ReactComponent as Cash } from "../../../assets/icons/cash.svg";
import { ReactComponent as Card } from "../../../assets/icons/card.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { getColumnSearchProps } from "../../../utils/tables";
import OrderDetail from "../../../components/OrderDetail";
import styles from "../styles/ClientsUI.module.css";

const ClientsUI = (props) => {
  const {
    loading,
    client,
    order,
    openOrder,
    closeOrder,
    exportData,
    handleActive,
    loadingOrder,
  } = props;

  const getIcon = (status) => {
    if (status === 4) {
      return <Cancel className={styles.driverIcon} />;
    }
    return <Check />;
  };

  const getMethod = (method) => {
    if (method === 1) {
      return <Cash />;
    }
    return <Card />;
  };

  const columns = [
    {
      title: "Ticket",
      dataIndex: "ticket",
      align: "center",
      ...getColumnSearchProps("ticket"),
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      align: "center",
      render: (val) => val.zone || "-",
    },
    {
      title: "Fecha",
      dataIndex: "created_time",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YY - hh:mma"),
    },
    {
      title: "Monto",
      dataIndex: "total",
      render: (val) => `$${parseFloat(val).toFixed(2)}`,
      align: "center",
    },
    {
      title: "Método",
      dataIndex: "payment_method",
      render: (val) => getMethod(val),
      align: "center",
      filters: [
        {
          value: 1,
          text: "Efectivo",
        },
        {
          value: 2,
          text: "Tarjeta",
        },
      ],
      onFilter: (value, record) => record.payment_method === value,
      filterMultiple: false,
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (val) => getIcon(val),
      align: "center",
      filters: [
        {
          value: 12,
          text: "Completadas",
        },
        {
          value: 15,
          text: "Canceladas",
        },
      ],
      onFilter: (value, record) => record.status === value,
      filterMultiple: false,
    },
    {
      title: "Ver detalle",
      dataIndex: "id",
      align: "center",
      render: (val) => (
        <button
          disabled={loadingOrder}
          onClick={() => openOrder(val)}
          className={styles.eye}
        >
          {loadingOrder === val ? <Spin size="small" /> : <Eye />}
        </button>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/ajustes/clientes"
        title={loading ? "Cliente" : `${client.name} ${client.lastname}`}
        actions={
          <div className={styles.actions}>
            <div className={styles.switchWrap}>
              <span>Activo</span>
              <Switch checked={client.active} onChange={handleActive} />
            </div>
            <Button size="large" type="primary" onClick={exportData}>
              Exportar
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={styles.infoWrap}>
            <Row>
              <Col span={12}>
                <div className={styles.infoCol}>
                  <span>{`Nombre: ${client.name} ${client.lastname}`}</span>
                  <span>{`Teléfono: ${client.mobile}`}</span>
                  <span>{`Correo: ${client.email}`}</span>
                  <span>{`DUI: ${client.identity}`}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className={cx(styles.infoCol, styles.borders)}></div>
                <div className={styles.infoCol}>
                  <span>{`Registro: ${moment(client.created_time).format(
                    "DD/MM/YYYY"
                  )}`}</span>
                  <span>{`Cumpleaños: ${moment(client.birthday).format(
                    "DD/MM/YYYY"
                  )}`}</span>
                  <span>
                    {`Primera compra: ${
                      client.orders.length
                        ? moment(client.orders[0].created_time).format(
                            "DD/MM/YY"
                          )
                        : "Sin compras"
                    }`}
                  </span>
                  <span>
                    {`Última compra: ${
                      client.orders.length
                        ? moment(
                            client.orders[client.orders.length - 1].created_time
                          ).format("DD/MM/YY")
                        : "Sin compras"
                    }`}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Table columns={columns} dataSource={client.orders} rowKey="id" />
        </div>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={order}
        width={1150}
        closable={false}
        onCancel={closeOrder}
      >
        <OrderDetail order={order} close={closeOrder} userView />
      </Modal>
    </div>
  );
};

export default ClientsUI;
