import { createSlice } from "@reduxjs/toolkit";
import {
  getSlides,
  newBanner,
  editBanner,
  deleteBanner,
  changeActive,
  uploadImage,
} from "../../api/Frontpage";

export const frontpageSlice = createSlice({
  name: "frontpage",
  initialState: {
    slides: [],
  },
  reducers: {
    setSlides: (state, action) => {
      state.slides = action.payload;
    },
    setBanner: (state, action) => {
      const index = state.slides.findIndex((el) => el.id === action.payload.id);
      if (index > -1) {
        state.slides[index] = action.payload;
      }
    },
    addBanner: (state, action) => {
      state.slides.push(action.payload);
    },
    removeBanner: (state, action) => {
      const index = state.slides.findIndex((el) => el.id === action.payload);
      if (index > -1) {
        state.slides.splice(index, 1);
      }
    },
    setActive: (state, action) => {
      const { banner, val } = action.payload;
      const index = state.slides.findIndex((el) => el.id === banner);
      state.slides[index].status = val;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSlides, setBanner, addBanner, removeBanner, setActive } =
  frontpageSlice.actions;

export const getAllSlides = () => async (dispatch) => {
  try {
    const response = await getSlides();
    if (!response.error && response.status === 200) {
      dispatch(setSlides(response.data.banners));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const activeBanner = (id, val) => async (dispatch) => {
  try {
    dispatch(setActive({ banner: id, val: !val }));
    const response = await changeActive(id, !val);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setActive({ banner: id, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setActive({ banner: id, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateSlide = (data) => async (dispatch) => {
  try {
    const response = await editBanner(data);
    if (!response.error && response.status === 200) {
      dispatch(setBanner(response.data.banner));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const createSlide = (data) => async (dispatch) => {
  try {
    const response = await newBanner(data);
    if (!response.error && response.status === 200) {
      dispatch(addBanner(response.data.banner));
      return {
        status: "success",
        banner: response.data.banner,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deleteSlide = (id) => async (dispatch) => {
  try {
    const response = await deleteBanner(id);
    if (!response.error && response.status === 200) {
      dispatch(removeBanner(id));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const newImage = (info) => async (dispatch) => {
  try {
    const response = await uploadImage(info);
    if (!response.error && response.status === 200) {
      dispatch(setBanner(response.data.banner[0]));
      return {
        status: "success",
        banner: response.data.banner[0],
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectSlides = (state) => state.frontpage.slides;

export default frontpageSlice.reducer;
